<template>
  <div>
    <b-alert
      :variant="alertVariant"
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
    <div class="alert-body">
        <span><strong>{{this.alertMessage}}</strong></span>
      </div>
    </b-alert>
    <h4>Resultado parcial</h4>
    <b-row v-if="this.poll">
      <b-col xl="6" class="ml-2">
        <div id="poll-results">
          <h5 class="mt-2">{{this.poll.question}}</h5>
          <ul v-if="this.poll.options[0].name != ''">
            <li v-for="option in this.poll.options">{{option.name}} - <span class="text-success"> {{option.votes}} Votos </span></li>
          </ul>
        </div>
        <b-button
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            v-if="active == true"
            class="mr-2"
            v-on:click="getCurrentPoll()"
          >
            Atualizar votos
        </b-button>
        <b-button
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="warning"
            v-if="active == true"
            v-on:click="closePoll(poll.id)"
            :key="key"
          >
            Encerrar enquete
        </b-button>
      </b-col>
    </b-row>
  </div>
  
</template>

<script>
import {
  BRow, BCol, BButton, BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      poll:false,
      dismissCountDown:0,
      countDownChanged:false,
      alertVariant: 'success',
      alertMessage: '',
      active : true,
      key:0
    }
  },
 async created() {
    try{
      let response = await this.$http.get('/polls/get-active-poll')
      this.poll = response.data
      this.key++;
    }catch(err){
      console.log(err)
    }
  },
  methods: {
    getCurrentPoll(){
      this.$http
        .get('/polls/get-active-poll')
        .then(res => {
          console.log(res.data)
          this.poll = res.data;
          console.log(this.poll.id)

        })
    },
    closePoll(pollId) {
      this.$http
        .post('/polls/admin/close-poll', {
          pollId: pollId,
        })
        .then(res => {
          console.log(res.data);
          this.active = false;
        })
    },    
  },
  filters: {
    
  }
}
</script>

<style>
#poll-results{
  padding: 10px;
}
#poll-results h4{
  font-size: 18px;
  font-weight: 100;
}
#poll-results ul{
  
}
#poll-results li{
  font-size: 15px;
  padding: 10px 0;
  list-style: none;
}
</style>
